var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" Password ")])],1)]),(_vm.hasCurrentPassword)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Current Password","label-for":"account-old-password"}},[_c('validation-provider',{attrs:{"vid":"password_current","name":"Current Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":"password_current","type":_vm.passwordFieldTypeOld,"state":errors.length > 0 ? false:null,"placeholder":"Old Password"},model:{value:(_vm.form.password_current),callback:function ($$v) {_vm.$set(_vm.form, "password_current", $$v)},expression:"form.password_current"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-new-password","label":"New Password"}},[_c('validation-provider',{attrs:{"vid":"password","name":"New Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":"New Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"password_confirmation","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":"New Password"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Change Password ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }