<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center mb-2">
          <feather-icon
            icon="UserIcon"
            size="18"
          />
          <h4 class="mb-0 ml-75">
            General
          </h4>
        </div>
      </b-col>
    </b-row>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="generalData.image"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="loadImage($event)"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="generalData.image = optionsLocal.image"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
      ref="form"
      tag="form"
    >
      <b-form
        class="mt-2"
        @submit.prevent="update"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-name"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.name"
                  name="name"
                  placeholder="Name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="email"
            >

              <b-form-input
                v-model="optionsLocal.email"
                name="email"
                placeholder="Email"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Mobile Number"
              label-for="account-e-mail"
            >
              <b-input-group prepend="+91">
                <b-form-input
                  v-model="optionsLocal.phone_number"
                  name="mobile"
                  placeholder="Mobile Number"
                  readonly
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Date of Birth"
              label-for="dob"
            >
              <b-form-input
                v-model="optionsLocal.dob"
                name="dob"
                placeholder="Date of Birth"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Location"
              label-for="city"
            >
              <validation-provider
                #default="{ errors }"
                vid="location"
                name="Location"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.location.city"
                  name="location"
                  placeholder="City / Place"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="State"
              label-for="state"
            >
              <validation-provider
                #default="{ errors }"
                vid="state"
                name="State"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.location.state"
                  name="state"
                  placeholder="State"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- alert -->

          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Edit Profile
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Image Cropper -->
    <b-modal
      id="image-crop"
      ok-title="Update Profile Image"
      modal-class="modal-primary"
      centered
      title="Adjust Image"
      @ok="uploadImage"
    >

      <div
        v-if="cropImageLoading"
        class="text-center"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>

      <cropper
        ref="cropper"
        class="cropper"
        :auto-zoom="true"
        :src="image.src"
        :stencil-props="{
          aspectRatio: 1,
        }"
        :canvas="{
          minHeight: 100,
          minWidth: 100,
          maxHeight: 250,
          maxWidth: 250,
        }"
        @ready="ready"
      />
    </b-modal>

  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BSpinner, BForm, BFormGroup, BInputGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    Cropper,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      cropImageLoading: true,

      image: {
        src: null,
        type: null,
      },
    }
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$refs.cropper.refresh()
    })
  },
  destroyed() {
    this.$root.$off('bv::modal::shown')
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
  methods: {
    async update() {
      const form = {
        name: this.optionsLocal.name,
        city: this.optionsLocal.location.city,
        state: this.optionsLocal.location.state,
        organiser: true,
      }
      await this.$http
        .post('/me/profile', form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Update failed',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          if (error.response.data) {
            const { errors } = error.response.data
            if (errors) {
              this.$refs.form.setErrors(errors)
            }
          }
        })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        this.generalData.image = canvas.toDataURL()
        const form = new FormData()
        const organisationId = localStorage.getItem('organizationSelected')
        canvas.toBlob(blob => {
          form.append('files', blob)
          form.append('organisation_id', organisationId)
          this.$http
            .post('/me/upload/avatar', form)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Avatar Updated',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.generalData.image = `${response.data.data.file_path}?${Math.floor(Date.now() / 1000)}`
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Avatar Update failed',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            })

          // Perhaps you should add the setting appropriate file format here
        }, 'image/jpeg')
      }
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      }
    },
    loadImage(event) {
      this.cropImageLoading = true
      this.$bvModal.show('image-crop')
      const { files } = event.target
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = e => {
          this.image = {
            src: blob,
            type: this.getMimeType(e.target.result, files[0].type),
          }
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    ready() {
      this.cropImageLoading = false
    },
    // This function is used to detect the actual image type,
    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i += 1) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
  },
}
</script>
