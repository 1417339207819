<template>
  <b-card>
    <validation-observer
      ref="form"
      tag="form"
    >
      <b-form @submit.prevent="update">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="LinkIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Profile Links
              </h4>
            </div>
          </b-col>

          <!-- instagram -->
          <b-col md="6">
            <b-form-group
              label="Instagram"
              label-for="account-instagram"
            >

              <validation-provider
                #default="{ errors }"
                vid="social.links.instagram"
                name="Instagram URL"
                rules="url"
              >
                <b-form-input
                  id="account-instagram"
                  v-model="social.links.instagram"
                  placeholder="Add link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ instagram -->

          <!-- youtube -->
          <b-col md="6">
            <b-form-group
              label-for="account-youtube"
              label="Youtube"
            >
              <validation-provider
                #default="{ errors }"
                vid="social.links.youtube"
                name="Youtube URL"
                rules="url"
              >
                <b-form-input
                  id="account-youtube"
                  v-model="social.links.youtube"
                  placeholder="Add link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- youtube -->

          <!-- facebook -->
          <b-col md="6">
            <b-form-group
              label-for="account-facebook"
              label="Facebook"
            >
              <validation-provider
                #default="{ errors }"
                vid="social.links.facebook"
                name="Facebook URL"
                rules="url"
              >
                <b-form-input
                  id="account-facebook"
                  v-model="social.links.facebook"
                  placeholder="Add link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group>
          </b-col>
          <!--/ facebook -->

          <!-- twitter -->
          <b-col md="6">
            <b-form-group
              label-for="account-twitter"
              label="Twitter"
            >
              <validation-provider
                #default="{ errors }"
                vid="social.links.twitter"
                name="Twitter URL"
                rules="url"
              >
                <b-form-input
                  id="account-twitter"
                  v-model="social.links.twitter"
                  placeholder="Add link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ twitter -->
          <!-- website -->
          <b-col md="6">
            <b-form-group
              label-for="account-website"
              label="Website"
            >
              <validation-provider
                #default="{ errors }"
                vid="website"
                name="Website URL"
                rules="url"
              >
                <b-form-input
                  id="account-website"
                  v-model="social.links.website"
                  placeholder="Add link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ website -->

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <!-- Profile Connections -->
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Social Connections
              </h4>
            </div>
            <b-row>
              <b-col
                cols="12"
              >
                <!-- google connect -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="m-1"
                  :variant="social.google ? 'success' : 'outline-primary'"
                >
                  <b-icon-google />
                  Google
                </b-button>
                <!--/ google connect -->
                <!-- facebook connect -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="m-1"
                  :variant="social.facebook ? 'success' : 'outline-primary'"
                >
                  <b-icon-facebook />
                  Facebook
                </b-button>
                <!--/ facebook connect -->
                <!-- twitter connect -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="m-1"
                  :variant="social.twitter ? 'success' : 'outline-primary'"
                >
                  <b-icon-twitter />
                  Twitter
                </b-button>
                <!--/ twitter connect -->
                <!-- discord connect -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="m-1"
                  :variant="social.discord ? 'success' : 'outline-primary'"
                >
                  <b-icon-discord />
                  Discord
                </b-button>
                <!--/ discord connect -->
                <!-- apple connect -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="m-1"
                  :variant="social.apple ? 'success' : 'outline-primary'"
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    focusable="false"
                    role="img"
                    width="1em"
                    height="1em"
                    aria-label="apple"
                    class="bi-apple b-icon bi"
                  >
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                  </svg>

                  Apple
                </b-button>

                <!--/ apple connect -->
              </b-col>
            </b-row>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              Update Social Information
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1 ml-25"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Cancel
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BIconGoogle, BIconFacebook, BIconTwitter, BIconDiscord,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const urlFixRule = value => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(value)
}
extend('url', {
  validate: val => urlFixRule(val), message: 'This value must be a valid URL',
})
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BIconGoogle,
    BIconFacebook,
    BIconTwitter,
    BIconDiscord,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // url,
      social: JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
    resetForm() {
      this.social = JSON.parse(JSON.stringify(this.socialData))
    },

    async update() {
      const form = {
        social: this.social,
        organiser: true,
      }

      this.$refs.form.reset()
      await this.$http
        .post('/me/profile', form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Links Updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Links failed',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })

          if (error.response.data) {
            const { errors } = error.response.data
            if (errors) {
              this.$refs.form.setErrors(errors)
            }
          }
        })
    },
  },
}
</script>
