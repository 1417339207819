<template>
  <b-card>
    <b-row>

      <b-col cols="12">
        <div class="d-flex align-items-center mb-2">
          <feather-icon
            icon="BellIcon"
            size="18"
          />
          <h4 class="mb-0 ml-75">
            Notifications
          </h4>
        </div>
      </b-col>
      <!-- application switch -->
      <h6 class="section-label mx-1 mt-0">
        Notification Preferences

      </h6>
      <b-col
        cols="12"
        class="mt-1 mb-2"
      >
        <b-form-checkbox
          v-model="localOptions.email"
          :checked="localOptions.email"
          name="email"
          switch
          inline
          @change="update"
        >
          <span>Email</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="localOptions.web"
          :checked="localOptions.web"
          name="web"
          switch
          inline
          @change="update"
        >
          <span>Web</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="localOptions.sms"
          :checked="localOptions.sms"
          name="sms"
          switch
          inline
          @change="update"
        >
          <span>SMS</span>
        </b-form-checkbox>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {

    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),

    }
  },
  methods: {
    async update() {
      const form = {
        notifications: this.localOptions,
        organiser: true,
      }
      await this.$http
        .post('/me/profile', form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notifications Updated',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notifications update failed',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
