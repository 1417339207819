<template>
  <b-tabs
    v-model="activeTabIndex"
    vertical
    content-class="col-12 col-sm-11 col-md-9 mt-n1 mt-sm-0"
    pills
    nav-wrapper-class="col-md-3 col-sm-1 col-12 mt-sm-1"
    nav-class="nav-left flex-row flex-sm-column ml-1 ml-sm-0"
    @input="changeRoute"
  >

    <!-- general tab -->
    <b-tab
      id="general"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold d-none d-md-flex">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab id="password">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold  d-none d-md-flex">Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- social links -->
    <b-tab id="social">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold  d-none d-md-flex">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    <!-- notification -->
    <b-tab id="notifications">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold  d-none d-md-flex">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notifications"
        :notification-data="options.notifications"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      activeTabIndex: 0,
      tabs: [
        'general',
        'password',
        'social',
        'notifications',
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.hash': function () {
      this.activeTab()
    },
  },
  beforeCreate() {
    this.$http.get('/organizer/settings').then(res => { this.options = res.data.data })
  },
  created() {
    this.activeTab()
  },
  methods: {
    activeTab() {
      const tab = this.$route.hash.replace('#', '')
      const tabIndex = this.tabs.indexOf(tab)
      if (tabIndex !== -1) {
        this.activeTabIndex = tabIndex
      }
    },
    changeRoute(value) {
      this.$router.push({ hash: this.tabs[value] }).catch(() => {})
    },
  },
}
</script>
